<template>
  <div class="hello">
    <h1>{{ name }}</h1>

 <p class="leading" style="font-size: 13px;">Send WhatsApp message without saving contact</p>
     <div class="container">
  
  <form >
    <div class="form-group">
      <!-- <vue-tel-input v-model="phone"></vue-tel-input> -->
      <vue-tel-input enabledCountryCode  autocomplete="off" mode="international" v-model="number" class="form-control" 
       type="tel"
        pattern="[0-9]*"
        autofocus
        inputmode="numeric"  placeholder="WhatsApp number" enableLocalPaste='true' ></vue-tel-input>
    </div>
    <div class="form-group">
     
      <textarea class="form-control" v-model="message" rows="2" multiline id="comment" placeholder="Message (Optional)"></textarea>
    </div>
       <img src="@/assets/send.png"  style="width: 48px;" @click="sendMessage"><br>
    <label class="text-danger" v-if="err">Please enter WhatsApp number..</label>
   
    <button hidden type="button" class="btn btn-primary text-white" @click="sendMessage"> <img src="@/assets/send.png"  style="width: 20px;"></button>
   
  </form>
</div>
  <div class="footer" style="padding-top:5em;">
     <p style="margin-bottom: 0px !important;">Powered by</p>
     <p><a href="http://linkwaysoftwares.com" target="_blank" style="text-decoration: none; color:#2D9EDE !important;">Linkway Software Solutions</a></p>
  </div> 

        

    
  </div>
</template>

<script>
import Vue from 'vue'
import VueTelInput from 'vue-tel-input'
 
Vue.use(VueTelInput)
// @ is an alias to /src
// import itemoffer from '@/components/Offer.vue'

export default {
   
  props: ['titleval'],
  data () {
    return {
     name:'LinksApp',
     number:'',
     message:'',
     err:false
     
    
    }
  },
  
    
  

methods: {
    sendMessage(){
      if (this.number !== '') {
           window.location.href = "https://api.whatsapp.com/send?phone=+"+this.number + '&text=%20' +this.message
          this.err = false
      }else
      {
             this.err = true
      }
     
    }
}


///ends

}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.footer
{
 /* margin-top: 50%; */
  /* position: relative; */
  /* position: fixed; */
  /* left: 0;
  bottom: 0;
  width: 100%;
  background-color:#fff0;
  color: #000;
  text-align: center; */
}
.container{
    min-height:100%;
    margin-bottom: -80px; /* Put negative height of the footer here */
    padding-bottom: 80px; /* Put height of the footer here. Needed for higher than screen height pages */
}
</style>
